import React from "react";
import "./style.less";

interface SeperatorComponent {
  text: string;
}

const SeperatorComponent = ({ text }: SeperatorComponent) => {
  return (
    <div id="seperator-component">
      <div className="seperator-line"></div>
      <div className="seperator-text">{text}</div>
      <div className="seperator-line"></div>
    </div>
  );
};

export default SeperatorComponent;

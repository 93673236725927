import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";

import {
  setLanguage,
  setAccessToken,
  getUserCountry,
} from "store/features/generalSlice";
import { getData } from "store/features/dataSlice";
import { Status } from "store/types/data";

import { UUID } from "store/types/paymentLink";
import { Outlet } from "react-router-dom";

// Custom hooks
import useNavigateWithQueryParams from "hooks/navigate";

import LoadingScreen from "./views/screens/loading";

const ApplicationWrapper = () => {
  const dispatch = useAppDispatch();
  const navigateWithQueryParams = useNavigateWithQueryParams();

  const { uuid } = useParams();

  const { status } = useAppSelector((state) => state.dataState.data);

  useEffect(() => {
    const controller = new AbortController();

    // Get access token from query params and save it in store
    const query = new URLSearchParams(window.location.search);
    const accessToken = query.get("access_token");
    dispatch(setAccessToken(accessToken));

    async function handleGetData(uuid: string) {
      const resultAction = await dispatch(
        getData({ uuid: uuid, controller: controller })
      );

      // Check if the request was rejected
      if (getData.rejected.match(resultAction)) {
        navigateWithQueryParams("/invalid");
      } else {
        const { payload } = resultAction;

        if (payload?.status !== "pending") {
          navigateWithQueryParams("/invalid");
        }

        // Initialize language when provided
        if (payload?.language) {
          dispatch(setLanguage(payload?.language));
        }
      }
    }

    async function handleGetCountry() {
      await dispatch(getUserCountry({ uuid: uuid, controller: controller }));
    }

    // Check whether the UUID is valid
    if (!uuid || !uuid.match(UUID)) {
      navigateWithQueryParams("/invalid");
    } else {
      // Load data
      if (status === Status.Idle) {
        handleGetData(uuid).then();
        handleGetCountry().then();
      }
    }
    return () => {
      controller.abort();
    };
  }, [uuid, dispatch, status]);

  return (
    <>
      {status === Status.Loading && <LoadingScreen />}
      {status === Status.Succeeded && <Outlet />}
    </>
  );
};

export default ApplicationWrapper;

import { useState, useEffect } from 'react';


/**
 * Custom React hook that determines if the current window width is less than or equal to a specified breakpoint.
 *
 * @param {number} [breakpoint=768] - The maximum width (in pixels) that qualifies as "mobile." Defaults to 768px.
 * @returns {boolean} - Returns `true` if the window width is less than or equal to the specified breakpoint, otherwise `false`.
 *
 * Example usage:
 * ```
 * const isMobile = useIsMobile();
 * ```
 */
const useIsMobile = (breakpoint: number = 768): boolean => {
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= breakpoint);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= breakpoint);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [breakpoint]);

  return isMobile;
};

export default useIsMobile;
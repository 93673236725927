import "./style.less";

const LoadingScreen = () => {
  return (
    <div id="loading-screen">
       <div className="loading-spinner"></div>
    </div>
  );
};

export default LoadingScreen;

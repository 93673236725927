import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

// Import icons
import { ReactComponent as DropDownIcon } from "assets/icons/select-dropdown.svg";
import { ReactComponent as ErrorIcon } from "assets/icons/error-field.svg";
import { ReactComponent as SuccessIcon } from "assets/icons/check.svg";

import "./style.less";

interface option {
  key: string;
  value: string;
}

interface SelectComponentProps {
  className?: string; // Additional class names to apply to the select component
  options: option[]; // Array of options to display in the dropdown
  selected: option; // The selected option
  label: string; // The label for the input field
  isRequired?: boolean; // Whether the input field is required
  errorMessage?: string; // The error message to display if the input field is invalid
  isValid?: boolean; // Whether the selected value is valid
  isFullWidth?: boolean; // Whether the button should take up the full width of its container
  onChange: Function; // The function to call when an option is selected
}

const SelectComponent = ({
  className,
  options,
  selected = { key: "", value: "" },
  label,
  isRequired = false,
  isValid = false,
  errorMessage,
  isFullWidth,
  onChange,
}: SelectComponentProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(selected);
  const [inputValue, setInputValue] = useState(""); // Empty input when dropdown opens
  const selectRef = useRef<HTMLDivElement>(null); // For outside click detection

  const selectClassNames = classNames(
    {
      "full-width": isFullWidth,
    },
    className
  );

  const { t } = useTranslation("components/select");

  // Handle outside click to close the dropdown
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
        setIsOpen(false); // Close the dropdown
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);

    // Reset input value to empty when the dropdown is opened
    if (!isOpen) {
      setInputValue(""); // Clear input when opening the dropdown
    }
  };

  const onSelectChange = (key: string, value: string) => {
    setSelectedOption({ key, value });
    setIsOpen(false);
    onChange && onChange({ key, value });
  };

  // Filter options based on the input value
  const filteredOptions = options.filter(({ value }) =>
    value.toLowerCase().includes(inputValue.toLowerCase())
  );

  const selectableOptions = filteredOptions.map(({ key, value }) => (
    <div
      key={key} // Use key if it's unique
      className={`option-item ${selectedOption.key === key ? "active" : ""}`}
      onClick={() => onSelectChange(key, value)}
    >
      <div className="value">{value}</div>
    </div>
  ));

  return (
    <div id="select-component" className={selectClassNames} ref={selectRef}>
      <div className="flex items-center">
        <div className="label">{label}</div>
        {!isRequired && (
          <sub className="optional-info">{`(${t("optional")})`}</sub>
        )}
      </div>

      <div className="select-container">
        <div
          className={`select-box ${isOpen ? "open" : ""}`}
          onClick={toggleDropdown}
        >
          {/* When the dropdown is open, show an input field with placeholder */}
          {isOpen ? (
            <input
              type="text"
              className="select-input-field"
              placeholder={t("search")} // Placeholder text
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)} // Filter options as user types
              autoFocus
            />
          ) : (
            <div className="selected-value">
              {selectedOption.value} <DropDownIcon className="dropdown-icon" />
            </div>
          )}

          {errorMessage && <ErrorIcon className="error-icon" />}
        </div>

        {/* Show the options list if dropdown is open */}
        {isOpen && <div className="options-list">{selectableOptions}</div>}
      </div>

      <div className="error-message">{errorMessage}</div>
    </div>
  );
};

export default SelectComponent;

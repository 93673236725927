import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams, useParams } from "react-router-dom";
import { useAppDispatch } from "store";
import { setAdditionalPaymentDetails } from "store/features/onlinePaymentSlice";

// Custom hooks
import useNavigateWithQueryParams from "hooks/navigate";

import "./style.less";

const RedirectScreen = () => {
  const { t } = useTranslation("views/screens/payment/redirect");
  const { uuid } = useParams();

  const dispatch = useAppDispatch();
  const navigateWithQueryParams = useNavigateWithQueryParams();

  // Retrieve redirect-result from URL
  const [searchParams] = useSearchParams();
  const redirectResult = searchParams.get("redirectResult");

  useEffect(() => {
    if (!redirectResult) return;

    const sendPaymentDetails = async () => {
      // Send additional payment details to the backend (redirect-result from 3DSecure payment)
      const response = await dispatch(
        setAdditionalPaymentDetails({
          redirectResult: redirectResult,
        })
      );

      if (response.payload?.result === "authorized") {
        // Payment authorized
        navigateWithQueryParams(`/${uuid}/status/authorized`);
      } else {
        // Payment failed
        navigateWithQueryParams(`/${uuid}/status/refused`);
      }
    };

    sendPaymentDetails();
  }, [redirectResult]);

  return (
    <div id="redirect-screen">
      <div className="loading-spinner"></div>
      <div className="text">{t("processingPayment")}</div>
    </div>
  );
};

export default RedirectScreen;

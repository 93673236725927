import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ProviderProps } from "store/types/provider";
import { useAppSelector, useAppDispatch } from "store";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from "yup";
import formatCurrency from "utils/currency";
import { getPaymentStatus } from "store/features/onlinePaymentSlice";

// Custom hook
import useIsMobile from "hooks/mobile";
import useNavigateWithQueryParams from "hooks/navigate";

// Components
import InputComponent from "components/input";
import SeperatorComponent from "components/seperator";
import ButtonComponent from "components/buttons";
import CheckboxComponent from "components/checkbox";

// Import icons
import CardImage from "assets/icons/card.png";
import { ReactComponent as BankDataIcon } from "assets/icons/bank-data.svg";
import { ReactComponent as SearchIcon } from "assets/icons/search.svg";
import { ReactComponent as LockIcon } from "assets/icons/lock.svg";

import "./style.less";

interface BankTransferScreenProps {
  provider: ProviderProps;
}

const BankTransferScreen = ({ provider }: BankTransferScreenProps) => {
  const [isBankInformationButtonActive, setIsBankInformationButtonActive] =
    useState(true);
  const [isRequestBankDataButtonActive, setIsRequestBankDataButtonActive] =
    useState(false);

  const { t } = useTranslation("views/screens/checkout/bank-transfer");

  const dispatch = useAppDispatch();

  // Selected language
  const currentLanguage = useAppSelector(
    (state) => state.generalState.general.language
  );

  const { amount, currency } = useAppSelector((state) => state.dataState.data);

  // Must match with media query in file "style.less"
  const isMobileView = useIsMobile(1500);
  
  const navigateWithQueryParams = useNavigateWithQueryParams();
  const { uuid } = useParams();

  useEffect(() => {
    // Scroll to the top of the screen when the component is mounted on mobile
    if (isMobileView) {
      const content = document.getElementById("bank-transfer-screen");
      content?.scrollIntoView();
    }
  }, []);

  const sanitizeIban = (iban: string) => {
    // Remove everything except alphanumeric characters
    return iban.replace(/\s+/g, "").replace(/[^A-Za-z0-9]/g, "");
  };

  // Function to convert letters to their corresponding numbers
  const convertLetterToNumber = (char: string) => {
    const code = char.toUpperCase().charCodeAt(0);
    return code >= 65 && code <= 90 ? (code - 55).toString() : char; // A = 10, B = 11, ..., Z = 35
  };

  const convertIbanToNumeric = (iban: string) => {
    // Move first 4 characters to the end
    const rearrangedIban = iban.slice(4) + iban.slice(0, 4);

    // Convert each letter to its corresponding number and concatenate the string
    return rearrangedIban
      .split("")
      .map((char) => convertLetterToNumber(char))
      .join("");
  };

  // Function to validate the IBAN using the mod-97 check
  // For more information see: https://en.wikipedia.org/wiki/International_Bank_Account_Number#:~:text=An%20IBAN%20is%20validated%20by,valid%2C%20the%20remainder%20equals%201.
  // Function to validate the IBAN using the mod-97 check
  const validateIbanChecksum = (iban: string) => {
    const sanitizedIban = sanitizeIban(iban);

    // Ensure that after sanitization, the IBAN only contains valid alphanumeric characters
    if (!/^[A-Za-z0-9]+$/.test(sanitizedIban)) {
      return false; // Return false if sanitized IBAN contains any invalid characters
    }

    const numericIban = convertIbanToNumeric(sanitizedIban);

    // Perform mod-97 check
    const ibanNumber = BigInt(numericIban);
    return ibanNumber % 97n === 1n; // The IBAN is valid if the remainder is 1
  };

  const formik = useFormik({
    validateOnMount: true,
    initialValues: {
      iban: "",
      name: "",
      email: "",
      termsAndConditions: false,
    },
    validationSchema: Yup.object({
      iban: Yup.string()
        .required(t("errors.iban.required"))
        .test(
          "iban-checksum",
          t("errors.iban.invalidFormat"),
          function (value) {
            if (!value) return false; // If IBAN is empty, return false
            return validateIbanChecksum(value); // Validate IBAN using the checksum
          }
        ),
      email: Yup.string().email(t("errors.email.invalid")),
      termsAndConditions: Yup.boolean()
        .required("Required")
        .oneOf([true], t("errors.termsAndConditions.required")),
    }),
    onSubmit: async (values) => {
      // Check whether payment is still pending
      const paymentStatusAction = await dispatch(
        getPaymentStatus({ uuid: uuid })
      );
      const { payload } = paymentStatusAction;
      if (payload?.status !== "pending") {
        navigateWithQueryParams("/invalid");
        return;
      }

      alert(JSON.stringify(values, null));
    },
  });

  return (
    <div id="bank-transfer-screen">
      <img className="card" src={CardImage} alt="Card" />

      <div className="form-container">
        <div className="iban-container">
          <InputComponent
            name="iban"
            className="iban-input"
            isFullWidth={true}
            isRequired={true}
            label={t("iban")}
            placeholder="IT60X0542811101000000123456"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            allowSpecialCharacters={false}
            isValid={formik.touched.iban && !formik.errors.iban}
            errorMessage={formik.touched.iban && formik.errors.iban}
            value={formik.values.iban}
          />

          {!isMobileView && (
            <div className="button-container">
              <ButtonComponent
                className="request-bank-data-button"
                expandableText={t("requestBankData")}
                isExpandable={true}
                isExpanded={isBankInformationButtonActive}
                icon={<SearchIcon />}
                isPrimary={true}
                hasBoxShadow={false}
                onClick={() =>
                  navigateWithQueryParams(`/${uuid}/bank-transfer/request-bank-data`)
                }
              />

              <ButtonComponent
                expandableText={t("ourBankData")}
                isExpandable={true}
                isExpanded={isRequestBankDataButtonActive}
                onExpand={(isExpanded) => {
                  setIsRequestBankDataButtonActive(isExpanded);
                  setIsBankInformationButtonActive(!isExpanded);
                }}
                icon={<BankDataIcon />}
                isPrimary={true}
                hasBoxShadow={false}
                onClick={() =>
                  navigateWithQueryParams(`/${uuid}/bank-transfer/bank-information`)
                }
              />
            </div>
          )}
        </div>

        <div className="form-input-container">
          <InputComponent
            name="name"
            isFullWidth={true}
            isRequired={false}
            label={t("name")}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            isValid={
              formik.values.name && formik.touched.name && !formik.errors.name
            }
            errorMessage={formik.touched.name && formik.errors.name}
          />

          <InputComponent
            name="email"
            isFullWidth={true}
            isRequired={true}
            label={t("email")}
            isRequired={false}
            isValid={
              formik.values.email &&
              formik.touched.email &&
              !formik.errors.email
            }
            errorMessage={formik.touched.email && formik.errors.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </div>

        <CheckboxComponent
          name="termsAndConditions"
          setInnerHtml={true}
          label={t("termsAndConditions", {
            terms_url: `/${uuid}/terms`,
            privacy_url: `/${uuid}/privacy`,
          })}
          onChange={(value: boolean) => {
            formik.setFieldTouched("termsAndConditions", true);
            formik.setFieldValue("termsAndConditions", value);
          }}
          onBlur={formik.handleBlur}
          errorMessage={
            formik.touched.termsAndConditions &&
            formik.errors.termsAndConditions
          }
          checked={formik.values.termsAndConditions}
        />

        <ButtonComponent
          onClick={formik.handleSubmit}
          className={`pay-now-button ${!formik.isValid ? "is-disabled" : ""}`}
          icon={<LockIcon />}
          text={t("pay", {
            amount: formatCurrency(amount, currentLanguage, currency?.symbol),
          })}
          isPrimary={true}
          isFullWidth={true}
          hasBoxShadow={true}
        />

        {isMobileView && (
          <>
            <SeperatorComponent text={t("or")} />

            <div className="button-container">
              <ButtonComponent
                text={t("requestBankData")}
                isPrimary={true}
                isFullWidth={true}
                hasBoxShadow={false}
                onClick={() =>
                  navigateWithQueryParams(`/${uuid}/bank-transfer/request-bank-data`)
                }
              />

              <ButtonComponent
                text={t("ourBankData")}
                isPrimary={true}
                isFullWidth={true}
                hasBoxShadow={false}
                onClick={() =>
                  navigateWithQueryParams(`/${uuid}/bank-transfer/bank-information`)
                }
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default BankTransferScreen;

import React from "react"
import {useTranslation} from "react-i18next"

import {useAppDispatch, useAppSelector} from "store"

import {createPaymentLink} from "store/features/paymentLinkSlice"

import {Status as PaymentLinkStatus} from "store/types/paymentLink"
import {ProviderProps} from "store/types/provider"

import "./style.less"


interface DemoScreenProps {
  provider: ProviderProps
}

const DemoScreen = ({provider}: DemoScreenProps) => {
  const {t} = useTranslation("views/screens/demo")
  const dispatch = useAppDispatch()

  const status = useAppSelector(state => state.paymentLinkState.paymentLink.status)
  const url = useAppSelector(state => state.paymentLinkState.paymentLink.url)

  async function handleCreatePaymentLink() {
    await dispatch(createPaymentLink())
  }

  let container
  if (status === PaymentLinkStatus.Loading) {
    container = <div>Loading</div> // TODO
  } else if (status === PaymentLinkStatus.Succeeded) {
    if (url) {
      const localhostUrl = url.toString().replace(/https:\/\/[^/]+/, "http://localhost:3000");

      container = <div classNames="flex flex-column">
        {`${t("link")}: `}
        <a href={url.toString()}>{url.toString()}</a>
        <a href={localhostUrl}>{localhostUrl}</a>
      </div>
    } else {
      container = <div>Error 1</div> // TODO
    }
  } else if (status === PaymentLinkStatus.Failed) {
    container = <div>Error 2</div> // TODO
  }

  return (
    <div id="demo-screen">
      <h1>{t("title")}</h1>
      <h2>{provider.name}</h2>
      <div>
        <button onClick={async () => {
          await handleCreatePaymentLink()
        }}>{t("action.create.payment-link")}</button>
        {container}
      </div>
    </div>
  )
}

export default DemoScreen

export const UUID: RegExp = /^[0-9a-f]{8}(?:-[0-9a-f]{4}){3}-[0-9a-f]{12}$/

export enum Status {
  Idle      = "idle",
  Loading   = "loading",
  Succeeded = "succeeded",
  Failed    = "failed",
}

export interface PaymentLinkProps {
  // @formatter:off
  status   : Status
  url      : URL | null
  storeId  : number | null
  entityId : number | null
  reference: string | null
  currency : string | null
  amount   : number | null
  // @formatter:on
}

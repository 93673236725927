export enum Status {
  Idle      = "idle",
  Loading   = "loading",
  Paying    = "paying",
  Succeeded = "succeeded",
  Failed    = "failed",
}

export interface OnlinePaymentProps {
  // @formatter:off
  status        : Status
  paymentMethods: []
  paymentMethod: string;
  creditCard: CreditCard;
  pspReference: string;
  // @formatter:on
}

interface CreditCard {
  brand_name: string;
}


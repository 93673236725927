import { Providers } from "./constants";

export const getProvider = () => {
  const subdomain = getSubdomain(window.location.hostname);

  const main = Providers.find((provider) => provider.main);
  if (!main) throw new Error("No main provider specified.");

  if (subdomain === "") return main;
  const provider = Providers.find((provider) =>
    provider.subdomain.includes(subdomain)
  );

  if (!provider) return null;
  return provider;
};

const getSubdomain = (location: string) => {
  const locationParts = location.split(".");

  // Determine the main domain slice length based on TLD or localhost
  const sliceTill = locationParts.slice(-1)[0] === "localhost" ? -1 : -2;

  // Extract the subdomain parts and join with a dot
  return locationParts.slice(0, sliceTill).join(".");
};

// Function to set provider specific assets like favicon
export const setProviderAssets = async (provider: string = "default") => {
  // List of possible formats
  const formats = ["ico", "png", "jpg", "jpeg", "svg"];

  // Helper function to find a file URL if it exists
  const findFileUrl = async (type: string) => {
    for (let format of formats) {
      const url = `/assets/${provider}/${type}.${format}`;
      if (await fileExists(url)) return url;
    }
    return "";
  };

  // Set favicon
  const faviconUrl = await findFileUrl("favicon");
  if (faviconUrl) {
    let favicon = document.querySelector("link[rel*='icon']") as HTMLLinkElement;
    if (!favicon) {
      favicon = document.createElement("link");
      favicon.rel = "icon";
      document.head.appendChild(favicon);
    }
    favicon.href = faviconUrl;
  }
};

// Helper function to check if a file exists
const fileExists = async (url: string): Promise<boolean> => {
  try {
    const response = await fetch(url + `?timestamp=${new Date().getTime()}`, {
      method: "GET",
      headers: { "Cache-Control": "no-cache" },
    });

    // Handle non-200 responses
    if (response.status !== 200) {
      return false;
    }

    // Check if the response is an image
    const contentType = response.headers.get("Content-Type");
    if (!contentType || !contentType.startsWith("image")) {
      return false;
    }

    return true;
  } catch (error) {
    return false;
  }
};

import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppSelector } from "store";
import { ProviderProps } from "store/types/provider";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from "yup";

// Components
import InputComponent from "components/input";
import ButtonComponent from "components/buttons";
import SelectComponent from "components/select";
import CheckboxComponent from "components/checkbox";

// Custom hook
import useIsMobile from "hooks/mobile";

import "./style.less";

interface RequestBankDataProps {
  provider: ProviderProps;
}

const RequestBankdataScreen = ({ provider }: RequestBankDataProps) => {
  const { t } = useTranslation("views/screens/checkout/bank-transfer");
  const isMobileView = useIsMobile(1100);
  const { uuid } = useParams();
  const detectedUserCountry = useAppSelector(
    (state) => state.generalState.general.country
  );

  // TODO: Set countries. Are they sent by the API?
  const countries = [
    { key: "IT", value: "Italy" },
    { key: "DE", value: "Germany" },
    { key: "FR", value: "France" },
  ];

  const preSelectedCountry = countries.find(
    (country) => country.key === detectedUserCountry
  );

  // TODO: Set bank options. Use data retrieved by the API.
  const bankOptions = [
    { key: "raiffeisen", value: "Raiffeisen Kasse" },
    { key: "sparkasse", value: "Sparkasse" },
  ];

  useEffect(() => {
    // Scroll to the top of the screen when the component is mounted on mobile
    if (isMobileView) {
      const content = document.getElementById("request-bank-data-screen");
      content?.scrollIntoView();
    }
  }, []);

  const formik = useFormik({
    validateOnMount: true,
    initialValues: {
      country: preSelectedCountry,
      bank: "",
      username: "",
      name: "",
      email: "",
      termsAndConditions: false,
    },
    validationSchema: Yup.object({
      country: Yup.object().required(t("errors.country.required")),
      bank: Yup.object().required(t("errors.bank.required")),
      username: Yup.string().required(t("errors.username.required")),
      termsAndConditions: Yup.boolean()
        .required("Required")
        .oneOf([true], t("errors.termsAndConditions.required")),
      email: Yup.string().email(t("errors.email.invalid")),
    }),
    onSubmit: (values) => {
      // TODO: Call the API to request bank data
      alert(JSON.stringify(values, null));
    },
  });

  return (
    <div id="request-bank-data-screen">
      <div className="form-container">
        <div className="personal-details-container">
          <SelectComponent
            name="country"
            label={t("country")}
            isRequired={true}
            selected={preSelectedCountry}
            options={countries}
            onChange={(selected: boolean) => {
              formik.setFieldTouched("country", true);
              formik.setFieldValue("country", selected);
            }}
            onBlur={formik.handleBlur}
            errorMessage={formik.touched.country && formik.errors.country}
          />
          <div className="form-input-container">
            <SelectComponent
              name="bank"
              label={t("bank")}
              isRequired={true}
              isFullWidth={true}
              options={bankOptions}
              onChange={(selected: boolean) => {
                formik.setFieldTouched("bank", true);
                formik.setFieldValue("bank", selected);
              }}
              onBlur={formik.handleBlur}
              errorMessage={formik.touched.bank && formik.errors.bank}
            />

            <InputComponent
              name="username"
              isFullWidth={true}
              isRequired={true}
              label={t("username")}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isValid={formik.touched.username && !formik.errors.username}
              errorMessage={formik.touched.username && formik.errors.username}
            />
          </div>
          <div className="form-input-container personal-details">
            <InputComponent
              name="name"
              isFullWidth={true}
              label={t("name")}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isValid={
                formik.values.name && formik.touched.name && !formik.errors.name
              }
              errorMessage={formik.touched.name && formik.errors.name}
            />

            <InputComponent
              name="email"
              isFullWidth={true}
              isRequired={true}
              label={t("email")}
              isRequired={false}
              isValid={
                formik.values.email &&
                formik.touched.email &&
                !formik.errors.email
              }
              errorMessage={formik.touched.email && formik.errors.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>

          <CheckboxComponent
            name="termsAndConditions"
            setInnerHtml={true}
            label={t("termsAndConditions", {
              terms_url: `/${uuid}/terms`,
              privacy_url: `/${uuid}/privacy`,
            })}
            onChange={(value: boolean) => {
              formik.setFieldTouched("termsAndConditions", true);
              formik.setFieldValue("termsAndConditions", value);
            }}
            onBlur={formik.handleBlur}
            errorMessage={
              formik.touched.termsAndConditions &&
              formik.errors.termsAndConditions
            }
            checked={formik.values.termsAndConditions}
          />
        </div>

        <ButtonComponent
          className={`pay-now-button ${!formik.isValid ? "is-disabled" : ""}`}
          text={t("requestNow")}
          isPrimary={true}
          isFullWidth={true}
          hasBoxShadow={true}
          onClick={formik.handleSubmit}
        />
      </div>
    </div>
  );
};

export default RequestBankdataScreen;

import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Components
import ButtonComponent from "components/buttons";

// Import icons
import { ReactComponent as ArrowLeftIcon } from "assets/icons/arrow-left.svg";

import "./style.less";

const TermsScreen = () => {
  const { t } = useTranslation("views/screens/terms");
  const navigate = useNavigate();

  return (
    <div id="terms-screen">
      <div className="terms-container">
        <div className="title">{t("title")}</div>

        <div className="text">{t("text")}</div>
      </div>
    </div>
  );
};

export default TermsScreen;

import React, { useState } from "react";
import "./style.less";
import classNames from "classnames";

interface ButtonComponent {
  className?: string; // Additional class names to apply to the button
  text: string; // Text to display on the button
  type?: "button" | "submit" | "reset"; // The type of button
  onClick: Function; // Function to call when the button is clicked
  isPrimary?: boolean; // Whether the button is a primary or secondary button
  isFullWidth?: boolean; // Whether the button should take up the full width of its container
  icon?: React.ComponentType;
  isIconLeft?: boolean; // Whether the icon should be displayed on the left side of the text
  isIconRight?: boolean; // Whether the icon should be displayed on the right side of the text
  hasBoxShadow?: boolean; // Whether the button should have a box shadow
  isExpandable?: boolean; // Whether the button should expand to show text on hover
  isExpanded?: boolean; // Whether the button is currently expanded
  onExpand?: Function; // Function to call when the button is expanded
  expandableText?: string; // Text to display when the button expands
}

const ButtonComponent = ({
  className,
  text,
  type,
  onClick,
  isFullWidth = false,
  isPrimary = true,
  icon,
  isIconLeft = true,
  isIconRight = false,
  hasBoxShadow = false,
  isExpandable = false,
  isExpanded = false,
  onExpand,
  expandableText = "",
}: ButtonComponent) => {

  const buttonClassNames = classNames(
    {
      primary: isPrimary,
      secondary: !isPrimary,
      "full-width": isFullWidth,
      "box-shadow": hasBoxShadow,
      "is-expandable": isExpandable,
      "is-expanded": isExpanded,
    },
    className
  );

  if (isIconRight) {
    isIconLeft = false;
  }

  const handleMouseEnter = () => {
    if (isExpandable) {
      onExpand && onExpand(!isExpanded);
    }
  };

  const handleMouseLeave = () => {
    if (isExpandable) {
      onExpand && onExpand(!isExpanded);
    }
  };

  return (
    <button
      id="button-component"
      className={buttonClassNames}
      type={type}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={() => {
        onClick && onClick();
      }}
    >
      {!isExpanded && isIconLeft && icon && <div className="icon-left">{icon}</div>}
      <div className="button-text">{(isExpanded || !isExpandable) && (text || expandableText)}</div>
      {!isExpanded &&isIconRight && icon && <div className="icon-right">{icon}</div>}
    </button>
  );
};

export default ButtonComponent;

export const Providers = [
  {
    subdomain: ["develop.delta-pay", "staging.delta-pay", "delta-pay", "www"],
    name: "Delta Pay",
    key: "delta-pay",
    main: true,
  },
  {
    subdomain: ["develop.asa-pay", "staging.asa-pay", "asa-pay"],
    name: "ASA HOTEL",
    key: "asa-pay",
    main: false,
  },
  {
    subdomain: [
      "develop.orderando-pay",
      "staging.orderando-pay",
      "orderando-pay",
    ],
    name: "Orderando",
    key: "orderando",
    main: false,
  },
  {
    subdomain: ["develop.zimres-pay", "staging.zimres-pay", "zimres-pay"],
    name: "Zimres",
    key: "zimres",
    main: false,
  },
];

const formatCurrency = (amount: number, locale = "de", currencySymbol: string) => {
  const formattedAmount = new Intl.NumberFormat(locale, {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(amount);

  return locale === "en"
    ? `${currencySymbol} ${formattedAmount}`
    : `${formattedAmount} ${currencySymbol}`;
};

export default formatCurrency;

import { useNavigate, useLocation } from "react-router-dom";

/**
 * Custom hook to navigate while preserving current query parameters.
 *
 * @returns {Function} A function that navigates to a given path, appending current query parameters.
 */
function useNavigateWithQueryParams() {
  const navigate = useNavigate();
  const location = useLocation();

  return (path: string, newTab: boolean = false) => {
    // Create a URLSearchParams object from the current query parameters
    const currentParams = new URLSearchParams(location.search);

    // Construct the new URL with current query parameters
    const url = `${path}?${currentParams.toString()}`;

    // Navigate to the constructed URL
    if (newTab) {
      window.open(url, "_blank");
    } else {
      navigate(url);
    }
  };
}

export default useNavigateWithQueryParams;

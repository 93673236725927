import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import { store } from "store";

import App from "./app";

import "core/style/reset.less";
import "core/style/style.less";

const root = createRoot(document.getElementById("root") as HTMLElement);
root.render(
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
);

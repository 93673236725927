const getPaymentMethodBrand = (brand: string) => {
  const brands = {
    mc: "Mastercard",
    visa: "Visa",
    amex: "American Express"
  }

  return brands?.[brand];
};

export default getPaymentMethodBrand;

import i18n from "i18next"
import Backend from "i18next-http-backend"
import LanguageDetector from "i18next-browser-languagedetector"
import {initReactI18next} from "react-i18next"

import {setLanguage} from "store/features/generalSlice"

import {AvailableLanguages} from "store/types/general"

import {store} from "store"


/**
 * Find given language in available languages.
 *
 * @param languages - List of available languages.
 * @param targetLanguage - Language to search for (retrieved from i18n).
 *
 * @returns Target language if found, otherwise default language.
 */
export const getLanguage = (languages: typeof AvailableLanguages, targetLanguage: string): AvailableLanguages => {
  for (const language of Object.values(languages)) {
    if (language === targetLanguage) {
      return targetLanguage
    }
  }
  return AvailableLanguages.English
}

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    load         : "languageOnly",
    fallbackLng  : "en",
    debug        : false, // TODO process.env.NODE_ENV === "development",
    interpolation: {
      escapeValue: false,
    },
    keySeparator : ".",
    backend      : {
      loadPath: "/locales/{{ns}}/{{lng}}.json",
    },
  })
  .then(() => {
    const language = getLanguage(AvailableLanguages, i18n.language)
    store.dispatch(setLanguage(language))
  })

export default i18n

import { useState, useEffect } from "react";
import { ProviderProps } from "store/types/provider";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "store";

// Custom hook
import useIsMobile from "hooks/mobile";

// Import icons
import { ReactComponent as CopyIcon } from "assets/icons/copy.svg";
import { ReactComponent as CheckIcon } from "assets/icons/check.svg";

import "./style.less";

const BankInformationScreen = () => {
  const [hasCopiedReferenceNumber, setHasCopiedReferenceNumber] =
    useState(false);
  const { t } = useTranslation("components/bank-information");
  const isMobileView = useIsMobile(1100);

  const currentLanguage = useAppSelector(
    (state) => state.generalState.general.language
  );

  // Retrieve data
  const { store, referenceCode } = useAppSelector(
    (state) => state.dataState.data
  );

  const title = store?.bank_account?.title?.[currentLanguage];
  const description = store?.bank_account?.description?.[currentLanguage];
  const designation = store?.bank_account?.designation?.[currentLanguage];

  useEffect(() => {
    // Scroll to the top of the screen when the component is mounted on mobile
    if (isMobileView) {
      const content = document.getElementById("bank-information");
      content?.scrollIntoView();
    }
  }, []);

  const copyReferenceNumber = () => {
    navigator.clipboard.writeText(referenceCode);
    setHasCopiedReferenceNumber(true);
  };

  return (
    <div id="bank-information">
      <div className="title">{title}</div>
      <div className="description">{description}</div>

      <div className="bank-details">
        <div className="item">
          <div className="label">{t("bank")}</div>
          <div className="value">{designation}</div>
        </div>

        <div className="item">
          <div className="label">{t("iban")}</div>
          <div className="value">{store?.bank_account?.iban}</div>
        </div>

        <div className="item has-sub-items">
          {store?.bank_account?.blz && (
            <div className="sub-item">
              <div className="label">{t("blz")}</div>
              <div className="value">{store?.bank_account?.blz}</div>
            </div>
          )}

          {store?.bank_account?.bic && (
            <div className="sub-item">
              <div className="label">{t("bic")}</div>
              <div className="value">{store?.bank_account?.bic}</div>
            </div>
          )}
        </div>

        <div
          className={`item reference-number ${
            hasCopiedReferenceNumber ? "copied" : ""
          }`}
        >
          <div className="flex">
            <div className="label">{t("referenceNumber")}</div>
            <div className="value">{referenceCode}</div>
          </div>
          {!hasCopiedReferenceNumber && (
            <CopyIcon className="copy-icon" onClick={copyReferenceNumber} />
          )}
          {hasCopiedReferenceNumber && <CheckIcon />}
        </div>
      </div>
    </div>
  );
};

export default BankInformationScreen;

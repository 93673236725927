import {configureStore} from "@reduxjs/toolkit"
import {TypedUseSelectorHook, useDispatch, useSelector} from "react-redux"

import dataReducer from "./features/dataSlice"
import generalReducer from "./features/generalSlice"
import onlinePaymentReducer from "./features/onlinePaymentSlice"
import paymentLinkReducer from "./features/paymentLinkSlice"


export const store = configureStore({
  reducer: {
    dataState: dataReducer,
    generalState      : generalReducer,
    onlinePaymentState: onlinePaymentReducer,
    paymentLinkState  : paymentLinkReducer,
  }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

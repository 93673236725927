import { useTranslation } from "react-i18next";

import Lottie from "lottie-react";
import animationData from "assets/animations/checkout_failed.json";

// Custom hook
import useIsMobile from "hooks/mobile";

import "./style.less";

const InvalidLinkScreen = () => {
  const { t } = useTranslation("views/screens/invalid-link");

  const isMobileView = useIsMobile(750);

  return (
    <div id="invalid-link-screen">
      <div className="content">
        <Lottie
          animationData={animationData}
          loop={false}
          style={{ height: isMobileView ? 120 : 150, width: isMobileView ? 120 : 150 }}
        />
        <div className="title">{t("title")}</div>
        <div className="description">{t("description")}</div>
      </div>
    </div>
  );
};

export default InvalidLinkScreen;

export const UUID: RegExp = /^[0-9a-f]{8}(?:-[0-9a-f]{4}){3}-[0-9a-f]{12}$/;

export enum Status {
  Idle = "idle",
  Loading = "loading",
  Succeeded = "succeeded",
  Failed = "failed",
}

export interface DataProps {
  // @formatter:off
  status: Status;
  currency: Currency;
  language: string | null;
  paymentMethods: Array<string> | null;
  returnUrl: string | null;
  amount: number;
  referenceCode: string;
  provider: Provider | null;
  store: Store | null;
  paymentItems: PaymentItems[] | null; // Array of "Detail" type or null
  tokenize: boolean;
  title: TranslateableObject;
  description: TranslateableObject;
  messages: Message[];
  metadata: Metadata[];
  application: Application;
  hideCvc : boolean;
  // @formatter:on
}

export interface Application {
  name: string;
  version: string;
  device: Device;
}

export interface Device {
  name: string;
  version: string;
}
export interface Metadata {
  key: string;
  value: string;
}

export interface Message {
  title: TranslateableObject;
  description: TranslateableObject;
}

interface Currency {
  code: string;
  symbol: string;
}

interface Provider {
  designation: string;
  logo_base64: string;
}

interface TranslateableObject {
  [key: string]: string;
}

interface Store {
  reference_code: string;
  designation: string;
  logo_base64: string;
  bank_account: BankAccount;
}

interface BankAccount {
  title: TranslateableObject;
  description: TranslateableObject;
  designation: TranslateableObject;
  iban: string;
  blz: string;
  bic: string;
}

interface PaymentItems {
  title: TranslateableObject;
  description: TranslateableObject;
  amount: number;
  image_base64: string;
  period: Period;
}

interface Period {
  start: string;
  end: string;
}

interface DetailData {
  language: string;
  title: string;
  text: string;
  date: string;
  period: {
    from: string;
    to: string;
  };
}

import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useAppSelector } from "store";
import { ProviderProps } from "store/types/provider";

// Components
import ButtonComponent from "components/buttons";
import SeperatorComponent from "components/seperator";

// Custom hooks
import useNavigateWithQueryParams from "hooks/navigate";

// Import icons
import { ReactComponent as BankTransferIcon } from "assets/icons/bank-transfer.svg";
import { ReactComponent as CreditCardIcon } from "assets/icons/credit-card.svg";
import { ReactComponent as ApplePayIcon } from "assets/icons/apple-pay.svg";
import { ReactComponent as GooglePayIcon } from "assets/icons/google-pay.svg";
import { ReactComponent as PayPalIcon } from "assets/icons/paypal.svg";

// Custom hook
import useIsMobile from "hooks/mobile";

import "./style.less";

interface PaymentScreenProps {
  provider: ProviderProps;
}

const PaymentScreen = ({ provider }: PaymentScreenProps) => {
  const isMobileView = useIsMobile(1100);

  const navigateWithQueryParams = useNavigateWithQueryParams();

  const { t } = useTranslation("views/screens/payment");
  const { uuid } = useParams();

  const currentLanguage = useAppSelector(
    (state) => state.generalState.general.language
  );

  // Retrieve data
  const { paymentMethods, title, description, paymentItems } = useAppSelector(
    (state) => state.dataState.data
  );

  const hasInformationScreen = paymentItems?.length > 0;

  const handleRedirectCreditCard = (uuid: string) => {
    navigateWithQueryParams(`/${uuid}/cards`);
  };

  const handleRedirectBankTransfer = (uuid: string) => {
    navigateWithQueryParams(`/${uuid}/bank-transfer`);
  };

  useEffect(() => {
    if (paymentMethods?.length === 1) {
      if (paymentMethods?.includes("credit_card")) {
        navigateWithQueryParams(`/${uuid}/cards`);
      } else if (paymentMethods?.includes("bank_transfer")) {
        navigateWithQueryParams(`/${uuid}/bank-transfer`);
      }
    }
  }, [paymentMethods, uuid, navigateWithQueryParams]);
  
  return (
    <div
      id="payment-screen"
      className={hasInformationScreen ? "--has-information-screen" : ""}
    >
      <div className="info">
        <h1 className="title">{title?.[currentLanguage]}</h1>

        <p className="text">{description?.[currentLanguage]}</p>

        <div className="payment-methods-container">
          {paymentMethods?.includes("bank_transfer") && (
            <ButtonComponent
              className="payment-method"
              text={t("bankTransfer")}
              icon={<BankTransferIcon />}
              isPrimary={false}
              isFullWidth={true}
              onClick={() => handleRedirectBankTransfer(uuid)}
            />
          )}

          {paymentMethods?.includes("credit_card") && (
            <ButtonComponent
              className="payment-method"
              text={t("creditCard")}
              icon={<CreditCardIcon />}
              isFullWidth={true}
              onClick={() => handleRedirectCreditCard(uuid)}
            />
          )}

          {(paymentMethods?.includes("paypal") ||
            paymentMethods?.includes("apple_pay") ||
            paymentMethods?.includes("google_pay")) && (
            <div className="seperator-container">
              <SeperatorComponent text={t("or")} />
            </div>
          )}

          {paymentMethods?.includes("paypal") && (
            <ButtonComponent
              className="payment-method paypal"
              icon={<PayPalIcon />}
              isFullWidth={true}
            />
          )}

          <div className={`flex ${isMobileView ? "flex-wrap" : "gap-md"}`}>
            {paymentMethods?.includes("apple_pay") && (
              <ButtonComponent
                className="payment-method apple-pay"
                icon={<ApplePayIcon />}
                isIconRight={true}
                text={t("payWith")}
                isFullWidth={true}
              />
            )}

            {paymentMethods?.includes("google_pay") && (
              <ButtonComponent
                className="payment-method google-pay"
                icon={<GooglePayIcon />}
                isIconRight={true}
                text={t("payWith")}
                isFullWidth={true}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentScreen;

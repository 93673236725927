import { useState } from "react";

// Import icons
import { ReactComponent as CheckIcon } from "assets/icons/checkbox.svg";

import "./style.less";

interface CheckboxComponentProps {
  checked: boolean; // Whether the checkbox is initially checked
  onBlur?: Function; // The function to call when the checkbox loses focus
  onChange?: Function; // The function to call when the checkbox is checked or unchecked
  label?: string; // The label for the checkbox
  name?: string; // The name of the checkbox
  setInnerHtml?: boolean; // Whether to set the inner HTML of the checkbox
  errorMessage?: string; // The error message to display if the checkbox is invalid
}

const CheckboxComponent = ({
  checked,
  label,
  name,
  onChange,
  onBlur,
  setInnerHtml,
  errorMessage,
}: CheckboxComponentProps) => {
  const [isChecked, setIsChecked] = useState<boolean>(checked);

  const handleChange = () => {
    const newCheckedState = !isChecked;
    setIsChecked(newCheckedState);

    onChange && onChange(newCheckedState);
  };

  return (
    <div id="checkbox-component">
      <div className="flex items-center">
        <div className="checkbox-container">
          <input
            className="checkbox"
            type="checkbox"
            name={name}
            checked={isChecked}
            onBlur={onBlur}
            onChange={handleChange}
          ></input>
          {isChecked && (
            <div className="check-icon-container">
              {<CheckIcon className="check-icon" />}
            </div>
          )}
        </div>
        <label className="label" htmlFor="checkbox" onClick={handleChange}>
          {!setInnerHtml ? (
            label
          ) : (
            <div dangerouslySetInnerHTML={{ __html: label }} />
          )}
        </label>
      </div>
      <div className="error-message">{errorMessage}</div>
    </div>
  );
};

export default CheckboxComponent;

import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "store";

// Components
import LanguageSelectComponent from "components/language-select";
import ButtonComponent from "components/buttons";

// Import icons
import { ReactComponent as ArrowLeftIcon } from "assets/icons/arrow-left.svg";

import "./style.less";

interface HeaderContainerProps {
  className?: string;
}

const HeaderContainer = ({ className }: HeaderContainerProps) => {
  const { t } = useTranslation("container/header");

  const location = useLocation();
  const { uuid } = useParams();
  const navigate = useNavigate();

  // Retrieve data
  const { returnUrl, paymentMethods } = useAppSelector(
    (state) => state.dataState.data
  );

  let buttonText = t("return");

  if (
    (location.pathname === `/${uuid}/cards` && paymentMethods?.length > 1) ||
    (location.pathname === `/${uuid}/bank-transfer` &&
      paymentMethods?.length > 1)
  ) {
    buttonText = t("paymentMethods");
  }

  return (
    <div id="header-container" className={className}>
      {location.pathname.startsWith(`/${uuid}/bank-transfer`) ||
      location.pathname.startsWith(`/${uuid}/cards`) ||
      (returnUrl && location.pathname === `/${uuid}`) ? (
        <ButtonComponent
          className="return-button"
          text={buttonText}
          icon={<ArrowLeftIcon />}
          onClick={() => {
            if (
              location.pathname === `/${uuid}` ||
              (location.pathname === `/${uuid}/cards` &&
                paymentMethods?.length === 1) ||
              (location.pathname === `/${uuid}/bank-transfer` &&
                paymentMethods?.length === 1)
            ) {
              returnUrl && window.location.replace(returnUrl);
            } else {
              navigate(-1);
            }
          }}
        />
      ) : (
        <div />
      )}
      <LanguageSelectComponent />
    </div>
  );
};

export default HeaderContainer;

import { useTranslation } from "react-i18next";
import { useAppSelector } from "store";
import formatCurrency from "utils/currency";
import getPaymentMethodBrand from "utils/payment-methods";
// Components
import ButtonComponent from "components/buttons";
import HeaderContainer from "container/header";

import Lottie from "lottie-react";
import animationData from "assets/animations/checkout.json";

// Custom hook
import useIsMobile from "hooks/mobile";

// Icons
import { ReactComponent as DateIcon } from "assets/icons/date.svg";

import "./style.less";

const AuthorizedPaymentScreen = () => {
  const { t } = useTranslation("views/screens/payment/authorized");

  const isMobileView = useIsMobile(750);

  // Retrieve data
  const { amount, currency, paymentItems, returnUrl, messages, tokenize } =
    useAppSelector((state) => state.dataState.data);

  const { paymentMethod, pspReference, creditCard } = useAppSelector(
    (state) => state.onlinePaymentState.onlinePayment
  );

  // Selected language
  const currentLanguage = useAppSelector(
    (state) => state.generalState.general.language
  );

  let title = messages?.authorized?.title?.[currentLanguage];

  if (tokenize && amount === 0) {
    title = t("tokenisationSuccessful");
  }

  const description = messages?.authorized?.description?.[currentLanguage];

  return (
    <div id="authorized-payment-screen">
      <HeaderContainer className="header" />

      <div className="content">
        <Lottie
          animationData={animationData}
          loop={false}
          style={{ height: isMobileView ? 120 : 150, width: isMobileView ? 120 : 150 }}
        />

        <div className="title">{title}</div>

        {!(tokenize && amount === 0) && (
          <div className="description">{description}</div>
        )}

        <div className="box">
          {paymentItems?.map((item) => {
            const title = item?.title?.[currentLanguage];
            const description = item?.description?.[currentLanguage];

            const hasDatePeriod = item?.period?.start && item?.period?.end;

            return (
              <div className="header-container" key={crypto.randomUUID()}>
                <div className="header">
                  {!isMobileView && item?.image_base64 && (
                    <img
                      className="image"
                      src={item?.image_base64}
                      alt={title}
                    />
                  )}

                  <div className="box-content">
                    <div className="flex flex-column">
                      <div className="box-header">
                        <div className="title">{title}</div>
                        <div className="price">
                          {formatCurrency(
                            item?.amount,
                            currentLanguage,
                            currency?.symbol
                          )}
                        </div>
                      </div>
                      {!isMobileView ? (
                        <div className="description">{description}</div>
                      ) : (
                        <div className="description-container">
                          {item?.image_base64 && (
                            <img
                              className="image"
                              src={item?.image_base64}
                              alt={title}
                            />
                          )}
                          <div className="description">{description}</div>
                        </div>
                      )}
                    </div>

                    {item?.period && (
                      <div className="date">
                        <DateIcon className="date-icon" />
                        {!hasDatePeriod && item?.period?.start}
                        {hasDatePeriod &&
                          item?.period?.start + " - " + item?.period?.end}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}

          <div className="seperator"></div>

          <div className="content">
            {paymentMethod === "credit_card" && creditCard?.brand_name && (
              <div className="item">
                <div className="label">{t("paymentMethod")}</div>
                <div className="value">
                  {t(`paymentMethods.creditCard`)} -{" "}
                  {getPaymentMethodBrand(creditCard.brand_name)}
                </div>
              </div>
            )}

            <div className="item">
              <div className="label">{t("paymentReference")}</div>
              <div className="value">{pspReference ? pspReference : "-"}</div>
            </div>
          </div>

          <div className="seperator"></div>

          <div className="footer">
            <div className="item">
              <div className="label">{t("totalPrice")}</div>
              <div className="value">
                {formatCurrency(amount, currentLanguage, currency?.symbol)}
              </div>
            </div>
          </div>
        </div>

        {returnUrl && (
          <ButtonComponent
            text={t("returnHome")}
            isFullWidth={true}
            onClick={() => returnUrl && window.location.replace(returnUrl)}
            hasBoxShadow={true}
          />
        )}
      </div>
    </div>
  );
};

export default AuthorizedPaymentScreen;

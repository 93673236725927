import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "store";
import { setLanguage } from "store/features/generalSlice";
import { AvailableLanguages } from "store/types/general";

// Import icons
import { ReactComponent as DropDownIcon } from "assets/icons/select-dropdown.svg";

import "./style.less";

// Helper function to import icons dynamically
const loadLanguageIcon = async (language) => {
  try {
    const icon = await import(`assets/icons/languages/${language}.svg`);

    return icon.default;
  } catch (error) {
    return null;
  }
};

const LanguageSelectComponent = () => {
  const { t } = useTranslation("components/language");
  const dispatch = useAppDispatch();
  const activeLanguage = useAppSelector(
    (state) => state.generalState.general.language
  );

  const [isOpen, setIsOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(activeLanguage);
  const [icons, setIcons] = useState({});

  useEffect(() => {
    const fetchIcons = async () => {
      const iconPromises = Object.keys(AvailableLanguages).map(async (key) => {
        const language =
          AvailableLanguages[key as keyof typeof AvailableLanguages];
        const IconUrl = await loadLanguageIcon(language);
        return { [language]: IconUrl };
      });
      const resolvedIcons = await Promise.all(iconPromises);
      const iconsMap = resolvedIcons.reduce(
        (acc, icon) => ({ ...acc, ...icon }),
        {}
      );
      setIcons(iconsMap);
    };

    fetchIcons();
  }, []);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleLanguageSelect = (language) => {
    setSelectedLanguage(language);
    setIsOpen(false);
    dispatch(setLanguage(language));
  };

  const filteredLanguages = Object.keys(AvailableLanguages).filter((key) => {
    const language = AvailableLanguages[key as keyof typeof AvailableLanguages];
    return language !== selectedLanguage; // Exclude the selected language
  });

  const languages = filteredLanguages.map((key) => {
    const language = AvailableLanguages[key as keyof typeof AvailableLanguages];
    const IconUrl = icons[language];

    return (
      <div
        key={crypto.randomUUID()}
        className={`option-item ${
          selectedLanguage === language ? "active" : ""
        }`}
        onClick={() => handleLanguageSelect(language)}
      >
        {IconUrl && <img src={IconUrl} alt={language} className="flag-icon" />}
        <div className="value">{t(key)}</div>
      </div>
    );
  });

  return (
    <div id="language-select-component">
      <div
        className={`select-box ${isOpen ? "open" : ""}`}
        onClick={toggleDropdown}
      >
        {icons[selectedLanguage] && (
          <img
            src={icons[selectedLanguage]}
            alt={selectedLanguage}
            className="flag-icon"
          />
        )}

        <div className="selected-value">
          {t(
            Object.keys(AvailableLanguages).find(
              (key) =>
                AvailableLanguages[key as keyof typeof AvailableLanguages] ===
                selectedLanguage
            )
          )}

          <DropDownIcon className="dropdown-icon" />
        </div>
      </div>
      {isOpen && <div className="options-list">{languages}</div>}
    </div>
  );
};

export default LanguageSelectComponent;

import { ProviderProps } from "store/types/provider";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "store";
import { useNavigate, useParams } from "react-router-dom";
import formatCurrency from "utils/currency";
import getPaymentMethodBrand from "utils/payment-methods";

import Lottie from "lottie-react";
import animationData from "assets/animations/checkout_failed.json";

// Components
import ButtonComponent from "components/buttons";
import HeaderContainer from "container/header";

// Icons
import { ReactComponent as DateIcon } from "assets/icons/date.svg";

// Custom hook
import useIsMobile from "hooks/mobile";
import useNavigateWithQueryParams from "hooks/navigate";

import "./style.less";

interface RefusedPaymentScreenProps {
  provider: ProviderProps;
}

const RefusedPaymentScreen = ({ provider }: RefusedPaymentScreenProps) => {
  const { t } = useTranslation("views/screens/payment/refused");

  const navigateWithQueryParams = useNavigateWithQueryParams();

  const { uuid } = useParams();

  const isMobileView = useIsMobile(750);

  // Retrieve data
  const { amount, currency, paymentItems, messages } = useAppSelector(
    (state) => state.dataState.data
  );

  const { paymentMethod, pspReference, creditCard } = useAppSelector(
    (state) => state.onlinePaymentState.onlinePayment
  );

  // Selected language
  const currentLanguage = useAppSelector(
    (state) => state.generalState.general.language
  );

  const title = messages?.refused?.title?.[currentLanguage];
  const description = messages?.refused?.description?.[currentLanguage];

  return (
    <div id="refused-payment-screen">
      <HeaderContainer className="header" />

      <div className="content">
        <Lottie
          animationData={animationData}
          loop={false}
          style={{ height: isMobileView ? 120 : 150, width: isMobileView ? 120 : 150 }}
        />
        <div className="title">{title}</div>
        <div className="description">{description}</div>
        <div className="box">
          {paymentItems?.map((item) => {
            const title = item?.title?.[currentLanguage];
            const description = item?.description?.[currentLanguage];

            const hasDatePeriod = item?.period?.start && item?.period?.end;

            return (
              <div className="header-container" key={crypto.randomUUID()}>
                <div className="header">
                  {!isMobileView && item?.image_base64 && (
                    <img
                      className="image"
                      src={item?.image_base64}
                      alt={title}
                    />
                  )}

                  <div className="box-content">
                    <div className="flex flex-column">
                      <div className="box-header">
                        <div className="title">{title}</div>
                        <div className="price">
                          {formatCurrency(
                            item?.amount,
                            currentLanguage,
                            currency?.symbol
                          )}
                        </div>
                      </div>
                      {!isMobileView ? (
                        <div className="description">{description}</div>
                      ) : (
                        <div className="description-container">
                          {item?.image_base64 && (
                            <img
                              className="image"
                              src={item?.image_base64}
                              alt={title}
                            />
                          )}
                          <div className="description">{description}</div>
                        </div>
                      )}
                    </div>

                    {item?.period && (
                      <div className="date">
                        <DateIcon className="date-icon" />
                        {!hasDatePeriod && item?.period?.start}
                        {hasDatePeriod &&
                          item?.period?.start + " - " + item?.period?.end}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}

          <div className="seperator"></div>

          <div className="content">
            {paymentMethod === "credit_card" && creditCard?.brand_name && (
              <div className="item">
                <div className="label">{t("paymentMethod")}</div>
                <div className="value">
                  {t(`paymentMethods.creditCard`)} -{" "}
                  {getPaymentMethodBrand(creditCard.brand_name)}
                </div>
              </div>
            )}

            <div className="item">
              <div className="label">{t("paymentReference")}</div>
              <div className="value">{pspReference ? pspReference : "-"}</div>
            </div>
          </div>

          <div className="seperator"></div>

          <div className="footer">
            <div className="item">
              <div className="label">{t("totalPrice")}</div>
              <div className="value">
                {formatCurrency(amount, currentLanguage, currency?.symbol)}
              </div>
            </div>
          </div>
        </div>
        <ButtonComponent
          text={t("tryAgain")}
          isFullWidth={true}
          onClick={() => navigateWithQueryParams(`/${uuid}`)}
          hasBoxShadow={true}
        />
      </div>
    </div>
  );
};

export default RefusedPaymentScreen;
